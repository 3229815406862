import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../../components/Hero";
import Draw from "../../components/Draw";

const Landing = ({ name }) => {
  const navigate = useNavigate();

  const styles = {
    landing: {
      height: "calc(100% - 93px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  useLayoutEffect(() => {
    const checkScrollBottom = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        console.log("Bottom reached"); // Debugging log
        navigate("/portfolio");
      }
    };

    window.addEventListener("scroll", checkScrollBottom);

    return () => {
      window.removeEventListener("scroll", checkScrollBottom);
    };
  }, [navigate]);

  return (
    <>
      <main className="landing container" style={styles.landing}>
        <Draw />
        <Hero name={name} />
      </main>
    </>
  );
};

export default Landing;
