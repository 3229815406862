
import SocialIcons from "./SocialIcons";

/**
 * Represents the footer section of the website.
 *
 * @component
 */

const Footer = () => {

  // State to hold GitHub information
  


  return (
    <footer>
      <SocialIcons />

    </footer>
  );
};

export default Footer;
